import { number } from "@/mixins/number.js";

export const github = {
  data() {
    return {
      githubBaseUrl: "https://api.github.com/repos/",
    };
  },
  mixins: [number],
  methods: {
    fetchStars: async function (repoDetails) {
      /**
       * repoDetails needs to be a string in the form
       * of username/reponame
       *
       * This string will be appended to the base URL
       * in order to get the star count of the repo
       */
      const completeUrl = this.githubBaseUrl + repoDetails;
      const response = await fetch(completeUrl);
      const responseJson = await response.json();
      this.starsFetched = this.formatNumber(responseJson["stargazers_count"]);

      return this.starsFetched;
    },
  },
};
