<template>
  <div class="work--each__container">
    <div class="work--content">
      <div class="name-star text-xl font-semibold">
        {{ getWork.name }}
      </div>
    </div>
    <div class="desc md:text-lg mt-6">
      {{ getWork.desc }}
    </div>
    <div
      class="type font-jetbrains-mono uppercase font-medium mt-6 hidden"
    ></div>
    <div
      class="
        lang--stars
        flex
        items-center
        mt-6
        capitalize
        font-medium
        text-gray-700
      "
    >
      <div class="lang">
        <span title="Languages used"
          >{ {{ getWork.languages.join(", ") }} }</span
        >
      </div>
      <div v-if="starOrViewAvailable" class="stars--views flex items-center">
        <div class="separator mx-3">&bull;</div>
        <div
          class="stars flex items-center font-roboto-mono text-lg"
          :title="
            isViewCountAvailable ? 'Hits on the Web & API' : 'Stars on GitHub'
          "
        >
          <component
            :is="isViewCountAvailable ? 'LayoutIcon' : 'GithubIcon'"
            size="1x"
            class="mr-3"
          />
          <span class="text">
            {{ isViewCountAvailable ? getViewCount : getStars }}
          </span>
        </div>
      </div>
    </div>
    <div class="link md:text-xl text-lg mt-6">
      <a
        :href="getWork.link"
        target="_blank"
        rel="noopener noreferrer"
        class="link-base"
        >Check =></a
      >
    </div>
  </div>
</template>

<script>
import { LayoutIcon, GithubIcon } from "vue-feather-icons";
import { github } from "@/mixins/github.js";

export default {
  name: "WorkEach",
  components: { LayoutIcon, GithubIcon },
  mixins: [github],
  data() {
    return {
      starsFetched: "",
    };
  },
  props: {
    work: {
      type: Object,
      require: true,
    },
  },
  computed: {
    getWork() {
      return this.work;
    },
    starOrViewAvailable() {
      return this.isStarAvailable || this.isViewCountAvailable;
    },
    isStarAvailable() {
      return Boolean(this.work.starFetchLink);
    },
    isViewCountAvailable() {
      return "viewCount" in this.work;
    },
    getStars() {
      return this.starsFetched;
    },
    getViewCount() {
      return this.formatNumber(this.work.viewCount);
    },
  },
  async mounted() {
    if (this.isStarAvailable)
      this.starsFetched = await this.fetchStars(this.work.starFetchLink);
  },
};
</script>
