<template>
  <div class="generic--header__container">
    <div class="generic--header">
      <h1
        class="
          md:text-6xl
          text-4xl
          font-black font-merriweather
          md:leading-relaxed
          leading-normal
        "
      >
        {{ getHeading }}
      </h1>
      <p class="mt-12 md:text-2xl text-lg text-gray-700">
        {{ getText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenericHeader",
  props: {
    content: {
      type: Object,
      require: true,
    },
  },
  computed: {
    getHeading() {
      return this.content.heading;
    },
    getText() {
      return this.content.text;
    },
  },
};
</script>
