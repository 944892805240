<template>
  <div class="works--content">
    <div
      class="
        works--wrapper
        md:py-32
        py-24
        2xl:w-2/5
        xl:w-3/5
        md:w-4/5
        w-5/6
        mr-auto
        ml-auto
      "
    >
      <div class="top--head text-center">
        <generic-header :content="getHeaderContent" />
      </div>
      <div class="works--wrapper md:mt-40 mt-28">
        <work-list :projects="getProjects" />
      </div>
    </div>
  </div>
</template>

<script>
import WorkList from "./WorkList.vue";
import projectList from "@/static/projects.js";
import GenericHeader from "./GenericHeader.vue";

export default {
  components: { WorkList, GenericHeader },
  name: "WorksContent",
  computed: {
    getProjects() {
      return projectList;
    },
    getHeaderContent() {
      return {
        heading: "I talk code. I'm most fluent in Python!",
        text: "For backend I use Python with various frameworks. For frontend, I prefer VueJS as my framework with SCSS. Also Tailwind is awesome!",
      };
    },
  },
};
</script>
