const projectList = [
  {
    id: "ytmdl-web",
    type: "webapp",
    name: "Ytmdl",
    desc: "Web App to download songs with metadata included in the highest quality possible. Backend used ytmdl CLI.",
    languages: ["python", "vue"],
    link: "https://ytmdl.deepjyoti30.dev",
    viewCount: 219324,
  },
  {
    id: "emanates-repo",
    type: "Web Repo",
    name: "Emanates",
    desc: "Build a blog on top of GitHub Issues and Actions without any external dependency or API's in just a few mins",
    languages: ["nuxt", "devops"],
    link: "https://github.com/emanates/emanates-web",
    starFetchLink: "emanates/emanates-web",
  },
  {
    id: "ytmdl-repo",
    type: "Commandline Utility",
    name: "ytmdl",
    desc: "App to download songs with metadata included from sources like iTunes, Musicbrainz, LastFM etc in various formats",
    languages: ["python"],
    link: "https://github.com/deepjyoti30/ytmdl",
    starFetchLink: "deepjyoti30/ytmdl",
  },
  {
    id: "ytmdl-web-repo",
    type: "webapp",
    name: "ytmdl web v2",
    desc: "Ytmdl Web V2. Second iteration of Ytmdl Web with loads of fixes and built from scratch",
    languages: ["vue", "python"],
    link: "https://github.com/deepjyoti30/ytmdl-web-v2",
    starFetchLink: "deepjyoti30/ytmdl-web-v2",
  },
  {
    id: "downloader-cli-repo",
    type: "CLI library",
    name: "downloader-cli",
    desc: "A simple downloader with an awesome, customizable and responsive progressbar for the commandline.",
    languages: ["python"],
    link: "https://github.com/deepjyoti30/downloader-cli",
    starFetchLink: "deepjyoti30/downloader-cli",
  },
  {
    id: "simber",
    type: "library",
    name: "simber",
    desc: "A simple, minimal and powerfull logger for Python with the aim of fixing shortcomings of the official logger",
    languages: ["python"],
    link: "https://github.com/deepjyoti30/simber",
    starFetchLink: "deepjyoti30/simber",
  },
  {
    id: "yaf",
    type: "CLI Tool",
    name: "yaf - Yet Another Fetch",
    desc: "A minimal, elegant and customizable fetch tool to get some extra upvotes on unixporn",
    languages: ["golang"],
    link: "https://github.com/deepjyoti30/yaf",
    starFetchLink: "deepjyoti30/yaf",
  },
  {
    id: "blog",
    type: "webapp",
    name: "Blog",
    desc: "My personal blog built with Nuxt and Python/FastAPI where I occasionally write about stuff.",
    languages: ["python", "vue"],
    link: "https://blog.deepjyoti30.dev",
    viewCount: 49949,
  },
  {
    id: "startpage-repo",
    type: "Browser extension",
    name: "startpage",
    desc: "A Firefox / Chrome extension that provides a visually pleasing customizable startpage with options ranging from weather info to time",
    languages: ["javascript", "html"],
    link: "https://github.com/deepjyoti30/startpage",
    starFetchLink: "deepjyoti30/startpage",
  },
  {
    id: "repostatus",
    type: "webapp",
    name: "RepoStatus",
    desc: "App to get the happiness status of a GitHub repo by running a sentiment analysis engine on various parts of the repo.",
    languages: ["python", "vue"],
    link: "https://repostatus.deepjyoti30.dev",
    viewCount: 23492,
  },
  {
    id: "music-typing",
    type: "Webapp",
    name: "MusicTyping",
    desc: "An app where people can test their typing speed to the lyrics of songs they like. It leverages API's from YouTube and others.",
    languages: ["python", "vue"],
    link: "https://music-typing.netlify.app/",
  },
  {
    id: "quickwall-repo",
    type: "Commandline utility",
    name: "QuickWall",
    desc: "An intuitive utility for Linux users that allows users to set wallpapers from Unsplash and set the theme at the same time",
    languages: ["python"],
    link: "https://github.com/deepjyoti30/QuickWall",
    starFetchLink: "deepjyoti30/QuickWall",
  },
  {
    id: "playlistpy",
    type: "Webpage",
    name: "PlaylistPy",
    desc: `An app that creates curated "Spotify" playlists of an artist based on the users music listening tastes and habits`,
    languages: ["python", "vue"],
    link: "https://playlistpy.herokuapp.com/",
  },
];

export default projectList;
