<template>
  <div class="work--list__container">
    <div
      v-for="(value, id) in getProjects"
      :key="id"
      class="works--each py-6 border-l-4 border-black flex"
    >
      <div class="rank--container">
        <div
          class="
            rank
            rounded-full
            bg-black
            w-12
            h-12
            text-white text-center text-xl
            font-roboto-mono
            flex
            items-center
            justify-center
          "
        >
          {{ id + 1 }}
        </div>
      </div>
      <div class="content">
        <work-each :work="value" />
      </div>
    </div>
  </div>
</template>

<script>
import WorkEach from "./WorkEach.vue";

export default {
  components: { WorkEach },
  name: "WorkList",
  props: {
    projects: {
      type: Array,
      require: true,
    },
  },
  computed: {
    getProjects() {
      return this.projects;
    },
  },
};
</script>

<style lang="scss" scoped>
.work--list__container {
  .works--each {
    .rank--container {
      .rank {
        // The size of the circle is 3x3 rem;
        // We need to move it hald to the left
        // But the size of the border is  4px
        // So we will have to add half of it
        // from the left direction
        transform: translate(calc(-1 * (1.5rem + 2px)), -1rem);
      }
    }
  }
}
</style>
