<template>
  <div class="works__container">
    <works-content />
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import WorksContent from "../components/WorksContent.vue";
export default {
  components: { WorksContent, Footer },
  name: "Works",
};
</script>
